<template>
  <div class="subscribe-list">
    <reminder :textArr="tipArr"></reminder>
    <div style="padding: 36px 0 24px;">已开启订阅消息 ：{{count}}</div>
    <div>
      <el-table v-loading="loading" style="width: 100%;margin-bottom: 50px" class="thead-light" stripe
        :data="list" @selection-change="handleSelectionChange">
        <el-table-column width="120" label="序号">
          <template slot-scope="scope">
            {{(scope.$index +1)>9?scope.$index +1:'0'+(scope.$index +1)}}
          </template>
        </el-table-column>

        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :min-width="th.width || ''"
            :sortable="th.sortable" :key="index" show-overflow-tooltip>
            <template slot-scope="scope">
              <PopoverInputEdit v-if="th.prop == 'template_id' && [scope.$index].includes(scope.$index)"
                v-model="scope.row[th.prop]"
                @confirm="valueChange(scope.row.id,list[scope.$index][th.prop])" />
              <span v-else>&nbsp{{ scope.row[th.prop] | placeholder }}&nbsp</span>
            </template>
          </el-table-column>

        </template>
        <el-table-column width="100" label="开启/关闭">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.status == 1"
              @change="changeStatus(scope.row.id,scope.row.status)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <!--                        <el-button type="text" size="small" @click="preview(scope.row,scope.$index)">预览</el-button>-->
            <el-popover placement="left" width="350" trigger="click">
              <div class="popover-top">
                <div>
                  模板编号 ：{{template_code}}
                </div>
                <div class="main-name">{{scope.row.name}}</div>
                <div>
                  <el-form ref="form" label-position="left" label-width="120px">
                    <div style="font-size: 14px;display: flex;min-height: 40px" v-for="(item,i) in previews"
                      :key="i">
                      <div style="color: #999999;min-width:120px">
                        {{item.key}} :
                      </div>
                      <!--                                        <el-form-item style="color: #999999;" :label="item.key">-->
                      <div style="color:rgba(0, 0, 0, 0.65);">{{item.name}}</div>
                      <!--                                        </el-form-item>-->
                    </div>
                  </el-form>
                </div>
              </div>
              <div class="see">
                <div>查看详情</div>
                <div class="el-icon-arrow-right"></div>
              </div>
              <el-button slot="reference" type="text" size="small"
                @click="preview(scope.row.preview,scope.row.template_code)">预览</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import { getIndex, getStatus, getSave } from "../api/subscribe-config";
import Reminder from "../../common/components/Reminder.vue";
export default {
  components: { PopoverInputEdit, Reminder },
  data() {
    return {
      list: [],
      previews: [],
      tipArr: [
        "使用该功能前，先绑定微信小程序",
        "使用订阅消息前，需前往微信公众平台，登录小程序账号，在“基础信息” > “服务类目”中添加“工具 > 企业管理”服务类目",
        "最多可同时启用 50 个订阅消息",
      ],
      count: "",
      template_code: "",
      thead: [
        { label: "消息标题", prop: "name" },
        { label: "场景说明", prop: "mark" },
        { label: "服务类目", prop: "type" },
        { label: "模板ID", prop: "template_id", width: "200" },
      ],
      loading: false,
    };
  },
  created() {
    this.upDataIndex();
  },
  methods: {
    upDataIndex() {
      this.loading = true;
      getIndex()
        .then((res) => {
          this.list = res.data.list;
          this.count = res.data.count;
          this.loading = false;
        })
        .catch((err) => {});
    },
    handleSelectionChange(e) {},

    changeStatus(e, i) {
      let sta = i == 1 ? 0 : 1;
      getStatus({ id: e, status: sta })
        .then((res) => {
          this.$message.success("修改成功");
          this.upDataIndex();
        })
        .catch((err) => {});
    },
    valueChange(e, i) {
      getSave({ id: e, template_id: i })
        .then((res) => {})
        .catch((err) => {});
    },
    preview(item, i) {
      console.log(item);
      this.previews = item;
      this.template_code = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe-list {
  /*height: 530px;*/
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .main {
    min-height: 167px;
    background: #ebf1ff;
    border: 1px solid #d7e4ff;
  }
  .main-title {
    color: #3576ff;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .main-context {
    font-size: 14px;
    line-height: 24px;
    ::v-deep.el-col-23 {
      display: flex;
      margin-left: 30px;
      max-width: 883px;
      span {
        line-height: 24px;
        padding-right: 10px;
      }
    }
  }
  ::v-deep.el-form-item {
    margin-bottom: 0px;
    min-height: 40px;
  }
  ::v-deep.el-form-item__label {
    line-height: inherit;
  }
  ::v-deep.el-form-item__content {
    line-height: inherit;
  }
}
.see {
  margin-top: 20px;
  padding: 19px 12px 7px;
  border-top: 1px solid rgba(245, 247, 250, 1);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
}
.main-name {
  font-size: 16px;
  color: #000;
  padding: 46px 0 32px 0;
  font-weight: 500;
}
.popover-top {
  padding: 18px 12px 0;
}
</style>
