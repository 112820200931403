import api from "../../../base/utils/request";
//列表
export const getIndex = data => {
    return api({
        url: '/admin/cyc/subscribe/index',
        method: 'post',
        data
    })
}
//状态
export const getStatus = data => {
    return api({
        url: '/admin/cyc/subscribe/updateStatus',
        method: 'post',
        data
    })
}
//保存模版
export const getSave = data => {
    return api({
        url: '/admin/cyc/subscribe/save',
        method: 'post',
        data
    })
}